import { UseMutationResult } from '@tanstack/react-query';
import { BotHolidayAPISchema } from './botHoliday';
import { MetaSchema } from './meta';

export enum BotEmailProvider {
  SENDGRID = 'send_grid',
  GMAIL = 'gmail',
  OUTLOOK = 'outlook',
}

export type BotSchema = BotApiSchema['attributes'] & {
  id: string | number;
  botErrors?: BotErrorSchema[];
};

export type BotSchemaWithWarmup = BotSchema & {
  botWarmUp: (BotWarmUpAPISchema['attributes'] & { id: string }) | null;
};

export interface BotEmailMetricsApiSchema {
  bouncedRate: number;
  openRate: number;
  responseRate: number;
  sentCount: number;
}

export interface BotEmailMetricsSchema {
  content: string | null;
  name: string;
  type: 'count' | 'rate' | 'text';
  value: number | string | null;
}

export interface BotUnsubscribePayload {
  unsubscribeText: string;
  useUnsubscribe: boolean;
}

export type BotWarmUpState = 'dormant' | 'active' | 'healthy';

export interface BotWarmUpAPISchema {
  attributes: {
    activeRate: number;
    botId: string | number;
    createdAt: string;
    currentRate: number;
    defaultActiveRate: number;
    defaultDormantRate: number;
    defaultHealthyRate: number;
    dormantRate: number;
    healthyRate: number;
    state: BotWarmUpState;
    updatedAt: string;
  };
  id: string;
  type: string;
}

export interface BotErrorAPISchema {
  attributes: {
    botId: number;
    category: string;
    createdAt: string;
    details: string;
    updatedAt: string;
  };
  id: string;
  type: 'bot_errors';
}
export type BotErrorSchema = BotErrorAPISchema['attributes'] & { id: string };

export interface BotWarmupRatesPayload {
  activeRate: number;
  dormantRate: number;
  healthyRate: number;
}

export type BotIncludedSchema = (BotWarmUpAPISchema | BotHolidayAPISchema | BotErrorAPISchema)[];

export interface BotApiSchema {
  attributes: {
    createdAt: string;
    email: string;
    emailDomain: string;
    emailProvider: string;
    name: string;
    outreachEmailsSentToday: number;
    paused: boolean;
    picture: string | null;
    signature: string;
    state: 'connected' | 'disconnected';
    // remove null once BE makes time zone mandatory
    timeZone: string | null;
    unsubscribeText: string;
    unsubscribeUrl: string;
    useUnsubscribe: boolean;
    // make mandatory once all bot fields in other endpoints are updated with emailMetrics and lastEmailSent
    emailMetrics?: BotEmailMetricsApiSchema;
    lastEmailSentAt?: string | null;
    // TODO_RESTRICTED_BOTS: Make `restricted` non-optional during restricted_bots cleanup
    restricted?: boolean;
  };
  id: string | number;
  relationships: {
    botWarmUp: {
      data: {
        id: string;
        type: 'bot_warm_up';
      };
    } | null;
    // TODO_RESTRICTED_BOTS: Make `latestUnresolvedBotErrors` non-optional during restricted_bots cleanup
    latestUnresolvedBotErrors?: {
      data: { id: string; type: 'bot_errors' }[];
    };
  };
  type: 'bots';
}

export interface BotsApiIndexSchema {
  data: BotApiSchema[];
  meta: MetaSchema;
  included?: BotIncludedSchema;
}

export interface BotsIndexPayload {
  page: number;
  include?: string[] | null;
  perPage?: number;
  q?: string | null;
}

export interface BotBounceRatesApiSchema {
  bouncedRate: number;
  date: string;
  sentCount: number;
}

export interface BotResolveErrorPayload {
  category: string;
  isResumingCampaigns: boolean;
}
export type BotResolveMutationResult = UseMutationResult<void, unknown, BotResolveErrorPayload>;

export interface SendGridBotPayload {
  emailAddress: string;
  name: string;
  token?: string;
}
export type SendGridBotCreateMutationResult = UseMutationResult<
  string | number,
  unknown,
  SendGridBotPayload
>;
